import { LookupServiceApiClient } from "raci-lookup-service-clientproxy";
import { MfaOtpApiClient } from "raci-otp-service-clientproxy";
import { useSessionIdStorageKey, useSsoApiClient } from "raci-react-library";
import {
  ADB2C_CLIENT_ID,
  BFF_API_BASE_URL,
  LOOKUP_SERVICE_API_BASE_URL,
  MFA_OTP_SERVICE_API_BASE_URL,
} from "../../constants";
import { PolicyCancellationsApiClient } from "raci-policy-cancellations-clientproxy";

const useRealBffApiClient = () => {
  const sessionIdStorageKey = useSessionIdStorageKey();
  return useSsoApiClient({
    Client: PolicyCancellationsApiClient,
    clientId: ADB2C_CLIENT_ID,
    apiBaseUrl: BFF_API_BASE_URL,
    sessionIdStorageKey,
  });
};

const useRealLookupServiceApiClient = () => {
  const sessionIdStorageKey = useSessionIdStorageKey();
  return useSsoApiClient({
    Client: LookupServiceApiClient,
    clientId: ADB2C_CLIENT_ID,
    apiBaseUrl: LOOKUP_SERVICE_API_BASE_URL,
    sessionIdStorageKey,
  });
};

const useRealMfaOtpServiceApiClient = () => {
  const sessionIdStorageKey = useSessionIdStorageKey();
  return useSsoApiClient({
    Client: MfaOtpApiClient,
    clientId: ADB2C_CLIENT_ID,
    apiBaseUrl: MFA_OTP_SERVICE_API_BASE_URL,
    sessionIdStorageKey,
  });
};

export let useApiClient = useRealBffApiClient;
export let useLookupServiceApiClient = useRealLookupServiceApiClient;
export let useMfaOtpServiceApiClient = useRealMfaOtpServiceApiClient;

if (
  process.env.REACT_APP_ENVIRONMENT === "LOCAL" ||
  process.env.REACT_APP_ENVIRONMENT === "DEV" ||
  process.env.REACT_APP_ENVIRONMENT === "SIT" ||
  process.env.REACT_APP_ENVIRONMENT === "UAT"
) {
  const { BypassService } = require("raci-react-library-npe");
  const {
    useNpeBffApiClient,
    useNpeLookupServiceApiClient,
    useNpeMfaOtpServiceApiClient,
  } = require("../../../__NPE__/useNpeApiClient");

  useApiClient = useNpeBffApiClient;
  useLookupServiceApiClient = useNpeLookupServiceApiClient;
  useMfaOtpServiceApiClient = useNpeMfaOtpServiceApiClient;

  if (process.env.REACT_APP_ENVIRONMENT === "UAT" && !BypassService.isUsingTestLaunchPage()) {
    useApiClient = useRealBffApiClient;
    useLookupServiceApiClient = useRealLookupServiceApiClient;
    useMfaOtpServiceApiClient = useRealMfaOtpServiceApiClient;
  }
}

export default useApiClient;
